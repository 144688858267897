import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  appSettingData: {
    error: {
      isError: false,
      message: "",
    },
    items: {},
    loading: false,
  },
  categoryMenuData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    loading: false,
  },
};

export const appSettingSlice = createSlice({
  name: "appSettingData",
  initialState,
  reducers: {
    setAppSettingData: (state, action) => {
      state.appSettingData.items = action.payload;
      state.appSettingData.error = {
        isError: false,
        message: "",
      };
    },
    setAppSettingLoading: (state, action) => {
      state.appSettingData.loading = action.payload;
    },
    setAppSettingError: (state, action) => {
      state.appSettingData.items = {};
      state.appSettingData.error = action.payload;
    },
    setCategoryMenuData: (state, action) => {
      state.categoryMenuData.items = action.payload;
      state.categoryMenuData.error = {
        isError: false,
        message: "",
      };
    },
    setCategoryMenuLoading: (state, action) => {
      state.categoryMenuData.loading = action.payload;
    },
    setCategoryMenuError: (state, action) => {
      state.categoryMenuData.items = {};
      state.categoryMenuData.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAppSettingData,
  setAppSettingLoading,
  setAppSettingError,
  setCategoryMenuData,
  setCategoryMenuLoading,
  setCategoryMenuError,
} = appSettingSlice.actions;

export default appSettingSlice.reducer;

export const appSettingDataSelector = (state) => state.appSettingData;
