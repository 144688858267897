export const STATUS_200 = 200;
export const STATUS_300 = 300;
export const STATUS_400 = 400;
export const SEARCH_LIMIT = 10;
export const DATA_LIMIT = 20;

export const SortingMethodsDataList = [
  {
    key: "popularity",
    value: "Popularity",
  },
  {
    key: "priceLowToHigh",
    value: "Price Low To High",
  },
  {
    key: "priceHighToLow",
    value: "Price High To Low",
  },
  {
    key: "newArrivals",
    value: "New Arrivals",
  },
  {
    key: "discountHighToLow",
    value: "Discount High to Low",
  },
];
