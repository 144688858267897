import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  walletData: {
    error: {
      isError: false,
      message: "",
    },
    items: {},
    loading: false,
  },
};

export const walletDataSlice = createSlice({
  name: "walletData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setWalletDataListing: (state, action) => {
      state.walletData.items = action.payload;
      state.walletData.error = {
        isError: false,
        message: "",
      };
    },
    setWalletDataListingLoading: (state, action) => {
      state.walletData.loading = action.payload;
    },
    setWalletDataListingError: (state, action) => {
      state.walletData.items = {};
      state.walletData.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setWalletDataListing,
  setWalletDataListingLoading,
  setWalletDataListingError,
} = walletDataSlice.actions;

export default walletDataSlice.reducer;

export const walletItemsSelector = (state) => state.walletData;
