import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  allReviewsListForPanel: {
    error: {
      isError: false,
      message: "",
    },
    items: {},
    loading: false,
  },
};

export const reviewsDataSlice = createSlice({
  name: "reviewData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllReviewListForPanel: (state, action) => {
      state.allReviewsListForPanel.items = action.payload;
      state.allReviewsListForPanel.error = {
        isError: false,
        message: "",
      };
    },
    setAllReviewListForPanelLoading: (state, action) => {
      state.allReviewsListForPanel.loading = action.payload;
    },
    setAllReviewListForPanelError: (state, action) => {
      state.allReviewsListForPanel.items = {};
      state.allReviewsListForPanel.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllReviewListForPanel,
  setAllReviewListForPanelLoading,
  setAllReviewListForPanelError,
} = reviewsDataSlice.actions;

export default reviewsDataSlice.reducer;

export const reviewItemsSelector = (state) => state.reviewData;
